//@author: Mandy
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25Util } from "../../util/s25-util";
import { OrganizationService } from "../../services/organization.service";
import { SpaceService } from "../../services/space.service";
import { ResourceService } from "../../services/resource.service";
import { EventService } from "../../services/event.service";
import { TaskEditService } from "../../services/task/task.edit.service";
import { TaskService } from "../../services/task/task.service";
import { CustomAttributeService } from "../../services/custom.attribute.service";
import { Fls } from "../../pojo/Fls";

declare global {
    interface Window {
        angBridge: any;
    }
}

export class S25BulkEditUtil {
    public static err(err: any, elementRef: any) {
        S25LoadingApi.destroy(elementRef);
        S25Util.showError(err);
    }

    public static done(elementRef: any) {
        S25LoadingApi.destroy(elementRef);
    }

    public static parseNoPermsList(resp: any) {
        let noPerms = S25Util.propertyGet(resp, "noPerms");
        return (noPerms && noPerms.items) || (noPerms && noPerms.event) || [];
    }

    public static parseLockedList(resp: any) {
        let locked = S25Util.propertyGet(resp, "locked");
        return (locked && locked.items) || (locked && locked.event) || [];
    }

    public static parseNoUpdateList(resp: any) {
        let noUpdate = S25Util.propertyGet(resp, "noUpdate");
        return (noUpdate && noUpdate.items) || (noUpdate && noUpdate.event) || [];
    }

    public static async getMulitTaskIds(itemIds: any, todo?: boolean) {
        let ret: any = [];
        for (let i = 0; i < itemIds.length; i++) {
            if (itemIds[i].indexOf("multi") > -1 || itemIds[i].indexOf("todo") > -1) {
                let curItem = itemIds[i];
                curItem = curItem.replace("task_multi_", "");
                curItem = curItem.replace("task", "");
                curItem = curItem.replace("todo", "");
                let getTask = await this.getTaskDetails(parseInt(curItem));
                let getMultiTaskIds: any = await this.getEventTaskApprovalIds(getTask.event_id);

                getMultiTaskIds = getMultiTaskIds.approval;
                let getCurItemAppName = getMultiTaskIds.filter(function (x: any) {
                    return x.approval_id.toString() === curItem;
                });

                getMultiTaskIds = getMultiTaskIds.filter(function (x: any) {
                    return x.approval_name === getCurItemAppName[0].approval_name;
                }); // ANG-4133, need to filter app name for multi tasks
                getMultiTaskIds = getMultiTaskIds.map((x: any) => {
                    return x.approval_id.toString();
                });

                ret = ret.concat(getMultiTaskIds);
            }
        }

        ret.length === 0 ? (ret = itemIds) : (ret = ret.concat(itemIds)); // when no mulit task found

        ret = ret.map((x: any) => x.replace("task_multi_", ""));
        ret = ret.map((x: any) => x.replace("task", ""));
        ret = ret.map((x: any) => x.replace("todo", ""));
        ret = S25Util.array.unique(ret);

        return ret;
    }

    public static getTaskDetails(curItem: number) {
        return TaskService.getTaskById(curItem).then((resp: any) => {
            return resp;
        });
    }

    public static getEventTaskApprovalIds(id: number) {
        return EventService.getEventInclude(id, "workflow", null, true).then((resp: any) => {
            return resp;
        });
    }
}

// do not edit type names -- these are used for event tracking
export const BulkEditTypes: any = {
    1: "event",
    2: "org",
    4: "location",
    6: "resource",
    7: "eventType",
    8: "requirement",
    9: "report",
    10: "task",
    11: "cabinet",
    12: "folder",
};

export const S25BulkMap: any = {
    1: {
        name: {
            singular: "event",
            plural: "events",
            service: EventService.updateEventName,
        },
        title: {
            singular: "title",
            plural: "titles",
            service: EventService.updateEventTitle,
        },
        message: {
            warningMsg:
                "Event deletion is permanent and cannot be undone. Deleting events will also remove all references to them.",
        },
        delete: {
            service: EventService.deleteEvents,
        },
        eventTypes: {
            searchCriteriaName: "eventTypes",
            service: EventService.microUpdate,
            serviceName: "eventMicro",
            serviceContext: "typeId",
            title: "Event Type",
            plural: "Event Types",
            singular: "Event Type",
            alterSelectMsg: "Please select a type.",
        },
        category: {
            service: EventService.updateEventCategories,
            searchCriteriaType: "eventCategories",
            hasQuantity: false,
            title: "Categories",
        },
        contactRoles: {
            searchCriteriaType: "eventRoles",
            searchCriteriaName: "eventRoles",
            service: EventService.updateEventContactRole,
            title: "Contact Role",
            plural: "Contact Roles",
            singular: "Contact Role",
            nodeNameVal: "roles",
            nodeIdVal: "roleId",
            alterSelectMsg: "Please select a role.",
        },
        primaryOrg: {
            searchCriteriaName: "organizations",
            service: EventService.updatePrimaryOrganization,
            title: "Primary Organization",
            plural: "Organizations",
            single: "Organization",
            alterSelectMsg: "Please select an organization.",
        },
        unassign: {
            searchCriteriaName: "locations",
            service: EventService.updateEventUnassign,
            title: "Unassign Location",
            plural: "Location",
            single: "Locations",
        },
        eventStates: {
            searchCriteriaName: "eventStates",
            service: EventService.updateEventState,
            title: "Event State",
            plural: "Event StateS",
            single: "Event State",
        },
        eventFolder: {
            searchCriteriaName: "eventFolders",
            serviceName: "updateFolder",
            service: EventService.updateFolder,
            title: "Event Folder",
            plural: "Event Folders",
            singular: "Event Folder",
            alterSelectMsg: "Please select a folder.",
        },

        eventOwner: {
            serviceName: "updateOwner",
            service: EventService.updateBulkEventOwner,
            title: "Event Owner",
            plural: "Event Owners",
            singular: "Event Owner",
            alterSelectMsg: "Please select a contact.",
        },
        //    custAtrb:{
        //      service:{
        //           add: CustomAttributeService.editCustomAttribute,
        //           remove: CustomAttributeService.delCustomAttribute,
        //      }
        //    }
    },
    2: {
        type: {
            searchCriteriaName: "organizationTypes",
            service: OrganizationService.updateType,
            title: "Type",
            plural: "Types",
            alterSelectMsg: "Please select a type.",
        },
        rating: {
            searchCriteriaName: "organizationRatings",
            service: OrganizationService.updateRating,
            title: "Rating",
            plural: "Ratings",
            alterSelectMsg: "Please select a rating.",
        },
        accountingCode: {
            service: OrganizationService.updateAccountingCode,
            title: "Accounting Code",
            maxLength: 40,
        },
        comment: {
            service: OrganizationService.updateText,
            title: "Comments",
            type: "comment",
        },
        category: {
            service: OrganizationService.updateCategories,
            searchCriteriaType: "organizationCategories",
            hasQuantity: false,
            title: "Categories",
        },
        contact: {
            service: OrganizationService.updateAssociatedContacts,
            title: "Associated Contacts",
        },
        name: {
            singular: "organization",
            plural: "organizations",
        },
        message: {
            warningMsg:
                "Organization deletion is permanent and cannot be undone. Deleting organizations will also remove all references to them, including in events.",
        },
        delete: {
            service: OrganizationService.deleteOrganizations,
        },
    },
    4: {
        comment: {
            service: SpaceService.updateText,
            title: "Comments",
            type: "comment",
        },
        instruction: {
            service: SpaceService.updateText,
            title: "Default Set Up Instructions",
            type: "instructions",
        },
        category: {
            service: SpaceService.updateCategories,
            searchCriteriaType: "locationCategories",
            hasQuantity: false,
            title: "Categories",
        },
        feature: {
            service: SpaceService.updateFeatures,
            searchCriteriaType: "locationFeatures",
            hasQuantity: true,
            title: "Features",
        },
        layout: {
            service: SpaceService.deleteLayouts,
            searchCriteriaType: "locationLayouts",
            hasQuantity: false,
            title: "Layouts",
        },
        name: {
            singular: "location",
            plural: "locations",
        },
        message: {
            warningMsg:
                "Location deletion is permanent and cannot be undone. Deleting locations will also remove all references to them, including assignments to events.",
        },
        delete: {
            service: SpaceService.deleteLocations,
        },
        buildings: {
            searchCriteriaName: "buildings",
            searchCriteriaType: "buildings",
            service: SpaceService.microUpdate,
            serviceName: "spaceMicro",
            payloadItem: "buildingId",
            title: "Building",
            plural: "Buildings",
            alterSelectMsg: "Please select a building.",
        },
    },
    6: {
        comment: {
            service: ResourceService.updateText,
            title: "Comments",
            type: "comment",
        },
        instruction: {
            service: ResourceService.updateText,
            title: "Default Set Up Instructions",
            type: "instructions",
        },
        category: {
            service: ResourceService.updateCategories,
            searchCriteriaType: "resourceCategories",
            hasQuantity: false,
            title: "Categories",
        },
        name: {
            singular: "resource",
            plural: "resources",
        },
        message: {
            warningMsg:
                "Resource deletion is permanent and cannot be undone. Deleting resources will also remove all references to them, including assignments to events.",
        },
        delete: {
            service: ResourceService.deleteResources,
        },
    },
    7: {
        category: {
            service: EventService.updateBulkEditEventType,
            searchCriteriaType: "eventCategories",
            hasQuantity: false,
            title: "Categories",
            plural: "Categories",
            singular: "Category",
            nodeNameVal: "categories",
            nodeIdVal: "categoryId",
        },
        activation: {
            service: EventService.updateBulkEditEventType,
        },
        eventTypeReports: {
            searchCriteriaType: "eventTypeReports",
            service: EventService.updateBulkEditEventType,
            title: "Report",
            plural: "Reports",
            singular: "Report",
            nodeNameVal: "reports",
            nodeIdVal: "reportId",
        },
        reportConfirm: {
            searchCriteriaName: "eventTypeReports", // s25-ng-bulk-edit-dropdown use searchCriteriaName instead of searchCriteriaType
            service: EventService.updateBulkEditEventType,
            title: "Reports: Confirmation Notice",
            plural: "Reports: Confirmation Notice",
        },
        reportInvoice: {
            searchCriteriaName: "eventTypeReports",
            service: EventService.updateBulkEditEventType,
            title: "Reports: Invoice",
            plural: "Reports: Invoice",
        },
        eventCustomAttributes: {
            searchCriteriaType: "eventCustomAttributes",
            service: EventService.updateBulkEditEventType,
            title: "Custom Attributes",
            plural: "Custom Attributes",
            singular: "Custom Attribute",
            nodeNameVal: "attributes",
            nodeIdVal: "attributeId",
        },
        eventRoles: {
            searchCriteriaType: "eventRoles",
            service: EventService.updateBulkEditEventType,
            title: "Contact Role",
            plural: "Contact Roles",
            singular: "Contact Role",
            nodeNameVal: "roles",
            nodeIdVal: "roleId",
        },
        eventRequirements: {
            searchCriteriaType: "eventRequirements",
            service: EventService.updateBulkEditEventType,
            title: "Calendar Requirement",
            plural: "Calendar Requirements",
            singular: "Calendar Requirement",
            nodeNameVal: "requirements",
            nodeIdVal: "requirementId",
        },
        eventOtherRequirements: {
            searchCriteriaType: "eventRequirements",
            service: EventService.updateBulkEditEventType,
            title: "Requirement",
            plural: "Requirements",
            singular: "Requirement",
            nodeNameVal: "requirements",
            nodeIdVal: "requirementId",
        },
    },
    10: {
        respondBy: {
            service: TaskEditService.update,
            title: "Respond by Date",
        },
        comment: {
            service: TaskEditService.update,
            title: "Comments",
            type: "comment",
        },
        assignee: {
            service: TaskEditService.update,
            title: "Assignee",
            type: "assignee",
        },
        action: {
            service: TaskEditService.update,
            title: "action",
            type: "action",
        },
    },
    11: {
        category: {
            service: EventService.updateEventCategories,
            searchCriteriaType: "eventCategories",
            hasQuantity: false,
            title: "Categories",
        },
    },
    12: {
        category: {
            service: EventService.updateEventCategories,
            searchCriteriaType: "eventCategories",
            hasQuantity: false,
            title: "Categories",
        },
        primaryOrg: {
            searchCriteriaName: "organizations",
            service: EventService.updatePrimaryOrganization,
            title: "Primary Organization",
            plural: "Primary Organizations",
            alterSelectMsg: "Please select an organization.",
        },
        organization: {
            searchCriteriaType: "organizations",
            service: EventService.updateOrganizations,
            title: "Additional Organization",
            plural: "Additional Organizations",
        },
    },
};

export const ItemActionsMap: Record<number, ActionListI[]> = {
    1: [
        { value: "editCat", name: "Add/Remove Event Categories" },
        { value: "addCustAtrb", name: "Add/Edit Event Custom Attribute" },
        { value: "removeCustAtrb", name: "Remove Event Custom Attribute" },
        { value: "addContactRole", name: "Add/Edit Contact Role" },
        { value: "removeContactRole", name: "Remove Contact Role" },
        { value: "eventName", name: "Edit Event Name" },
        { value: "eventTitle", name: "Edit Event Title" },
        { value: "editPrimaryOrg", name: "Edit Event Primary Organization" },
        { value: "eventType", name: "Edit Event Type" },
        { value: "eventState", name: "Edit Event State" },
    ],

    4: [
        { value: "editLayout", name: "Add/Edit Layouts" },
        { value: "removeLayout", name: "Remove Layouts" },
        { value: "editSpaceCap", name: "Edit Location Capacity" },
        { value: "editSpacefillRatio", name: "Edit Location Fill Ratio" },
        { value: "editComments", name: "Edit Location Comments" },
        { value: "editDefaultInstr", name: "Edit Location Default Setup Instructions" },
        { value: "editSpacePartition", name: "Edit Location Partition" },
        { value: "editSpaceScheduler", name: "Add/Remove Location Scheduler" },
        { value: "editCat", name: "Add/Remove Location Categories" },
        { value: "editSpaceFeature", name: "Add/Remove Location Features" },
        { value: "editNotifyPolicy", name: "Edit Notification Policy", fls_name: "SPACE_NOTIFY" },
        { value: "addCustAtrb", name: "Add/Edit Location  Custom  Attributes" },
        { value: "removeCustAtrb", name: "Remove Location  Custom Attributes" },
        { value: "editRelate", name: "Add/Remove Location Relationships" },
        { value: "editSpaceExpress", name: "Enable/Disable Location Express Scheduling" },
        { value: "editSpaceOptEffDating", name: "Add Optimizer Effective Dating" },
        { value: "editSpaceHours", name: "Edit Location Hours", fls_name: "SPACE_OPEN" },
        { value: "delete", name: "Delete Locations", fls_name: "SPACE_DEL" },
    ],

    2: [
        { value: "editComments", name: "Edit Organization Comments", fls_name: "CU_COMMENTS" },
        { value: "editNotifyPolicy", name: "Edit Notification Policy", fls_name: "CU_ACCT_NOTIFY" },
        { value: "editOrgTypes", name: "Edit Organization Types" },
        { value: "editOrgRating", name: "Edit Organization Rating", fls_name: "CU_ACCT_RATING" },
        { value: "editAcctCode", name: "Edit Accounting Code", fls_name: "CU_ACCT_NUMBERS" },
        { value: "editCat", name: "Add/Remove Organization Categories" },
        { value: "addCustAtrb", name: "Add/Edit Organization  Custom  Attributes" },
        { value: "removeCustAtrb", name: "Remove Organization  Custom Attributes" },
        { value: "editOrgContacts", name: "Add/Remove Associated Contacts" },
        { value: "editOrgPartitions", name: "Edit Organization Partitions", fls_name: "CU_ACCT_PREF" },
        { value: "delete", name: "Delete Organizations", fls_name: "CU_ACCT_DEL" },
    ],

    6: [
        { value: "editComments", name: "Edit Resource Comments" },
        { value: "editDefaultInstr", name: "Edit Resource Default Setup Instructions" },
        { value: "editNotifyPolicy", name: "Edit Notification Policy", fls_name: "RESOURCE_NOTIFY" },
        { value: "editCat", name: "Add/Remove Resource Categories" },
        { value: "addCustAtrb", name: "Add/Edit Resource  Custom  Attributes" },
        { value: "removeCustAtrb", name: "Remove Resource  Custom Attributes" },
        { value: "editRelate", name: "Add/Remove Resource Relationships" },
        { value: "editResStock", name: "Add Resource Stock Totals" },
        { value: "delete", name: "Delete Resources", fls_name: "RESOURCE_DEL" },
    ],

    7: [
        //{value: "editEveTypeVcalProcessing", name: "VCal Processing"}, // TC confirmed not something they need in bulk. ANG-3853
        { value: "editEventTypeReport", name: "Add/Remove Reports" },
        { value: "editCat", name: "Add/Remove Categories" },
        { value: "editAtrb", name: "Add/Remove  Custom  Attributes" },
        { value: "editRole", name: "Add/Remove Contact Roles" },
        { value: "editReqCal", name: "Add/Remove Requirements (Publish to Calendar)" },
        { value: "editReqOther", name: "Add/Remove Requirements (Other)" },
        { value: "editNotifyPolicy", name: "Edit Notification Policy" },
        { value: "editActivation", name: "Deactivate/Activate Event Types" },
    ],
    10: [
        { value: "editTaskAssignee", name: "Add Assignees" },
        { value: "editTaskActions", name: "Assign/Deny Tasks" },
        { value: "editTaskComments", name: "Edit Task Comments" },
        { value: "editTaskRespond", name: "Edit Task Respond by" },
    ],
    11: [
        { value: "editDateRange", name: "Update Date Range" },
        { value: "editCat", name: "Add/Remove Categories" },
        { value: "constraints", name: "Add Constraints" },
    ],
    12: [
        { value: "editDateRange", name: "Update Date Range" }, // edit folders need to have same parent
        { value: "editCat", name: "Add/Remove Categories" },
        { value: "constraints", name: "Add Constraints" },
        { value: "editPrimaryOrg", name: "Edit Primary Organization" },
        { value: "editOrg", name: "Add/Remove Additional Organization" },
        { value: "editInherit", name: "Inherit Folders" },
        { value: "editCopy", name: "Copy Folders" },
    ],
};
export interface ActionListI {
    value: string;
    name: string;
    fls_name?: keyof Fls;
}
