//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25BulkEditComponent } from "./s25.bulk.edit.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25BulkLocationEditFillratioComponent } from "./actions/s25.bulk.location.edit.fillratio.component";
import { S25BulkEditCommenttInstrComponent } from "./actions/s25.bulk.edit.comment.instr.component";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25BulkLocationEditLayoutCapacityComponent } from "./actions/s25.bulk.location.edit.layout.capacity.component";
import { S25BulkLocationEditSchedulerComponent } from "./actions/s25.bulk.location.edit.scheduler.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25BulkLocationEditPartitionComponent } from "./actions/s25.bulk.location.edit.partition.component";
import { S25BulkEditMultiselectComponent } from "./actions/s25.bulk.edit.multiselect.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25BulkEditNotifyPolicyComponent } from "./actions/s25.bulk.edit.notify.policy.component";
import { S25NotificationPolicyModule } from "../s25-notification-policy/s25.notification.policy.module";
import { S25BulkEditDropdownComponent } from "./actions/s25.bulk.edit.dropdown.component";
import { S25BulkEditTextComponent } from "./actions/s25.bulk.edit.text.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25BulkEditSaveComponent } from "./save/s25.bulk.edit.save.component";
import { S25BulkEditOrgContactsComponent } from "./actions/s25.bulk.edit.org.contacts.component";
import { S25OrgDetailsModule } from "../organization-details/s25.org.details.module";
import { S25BulkEditOrgPartitionPrefsComponent } from "./actions/s25.bulk.edit.org.partitions.component";
import { S25BulkEditRelationshipComponent } from "./actions/s25.bulk.edit.relationship.component";
import { S25BulkResourceEditStockComponent } from "./actions/s25.bulk.resource.edit.stock.component";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25BulkLocationEditExpressComponent } from "./actions/s25.bulk.location.edit.express.component";
import { S25BulkEventEditRequirementComponent } from "./actions/s25.bulk.event.edit.requirement.component";
import { S25EvDetailsModule } from "../event-details/s25.ev.details.module";
import { S25BulkLocationEditOptimizerEfectiveDating } from "./actions/s25.bulk.location.edit.optimizer.effective.dating.component";
import { S25BulkEditDeleteComponent } from "./actions/s25.bulk.edit.delete.component";
import { S25DeleteModule } from "../s25-delete/s25.delete.module";
import { S25BulkLocationEditHoursComponent } from "./actions/s25.bulk.location.edit.hours.component";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25BulkEditIssuesListComponent } from "./s25.bulk.edit.issues.list.component";
import { S25BulkEditInheritComponent } from "./actions/s25.bulk.edit.inherit.component";
import { S25BulkEditConstraintComponent } from "./actions/s25.bulk.edit.constraint.component";
import { S25BulkEditDateRangeComponent } from "./actions/s25.bulk.edit.date.range.component";
import { S25BulkEditActivationComponent } from "./actions/s25.bulk.edit.activation.component";
import { S25BulkEditEventTypeReportsComponent } from "./actions/s25.bulk.edit.event.type.reports.component";
import { S25BulkEditCopyComponent } from "./actions/s25.bulk.edit.copy.component";
import { S25ImageSelectorModule } from "../s25-image-selector/s25.image.selector.module";
import { S25ImageFormModule } from "../s25-image-form/s25.image.form.module";
import { S25ConstraintsModule } from "../s25-constraints/s25.constraints.module";
import { S25TaskModule } from "../s25-task/s25.task.module";
import { S25BulkEditDateComponent } from "./actions/s25.bulk.edit.date.component";
import { S25BulkEditAssigneeComponent } from "./actions/s25.bulk.edit.assignee.component";
import { S25BulkEditTaskActionComponent } from "./actions/s25.bulk.edit.task.action.component";
import { S25BulkEditEventTitleNameComponent } from "./actions/s25.bulk.edit.event.title.name.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25BulkEditUnassignComponent } from "./actions/s25.bulk.edit.event.unassign.component";
import { S25BulkEditStateComponent } from "./actions/s25.bulk.edit.event.state.component";
import { S25EventStatesModule } from "../s25-event-states/s25.event.states.module";
import { S25BulkEditCustAtrbComponent } from "./actions/s25.bulk.edit.cust.atrb.component";
import { S25BulkEditDataListComponent } from "./s25.bulk.edit.data.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25BulkEditEventOwnerComponent } from "./actions/s25.bulk.edit.event.owner.component";
import { S25HelpModule } from "../s25-help/s25.help.module";
@NgModule({
    declarations: [
        S25BulkEditComponent,
        S25BulkLocationEditFillratioComponent,
        S25BulkEditCommenttInstrComponent,
        S25BulkLocationEditLayoutCapacityComponent,
        S25BulkLocationEditSchedulerComponent,
        S25BulkLocationEditPartitionComponent,
        S25BulkEditMultiselectComponent,
        S25BulkEditNotifyPolicyComponent,
        S25BulkEditDropdownComponent,
        S25BulkEditTextComponent,
        S25BulkEditSaveComponent,
        S25BulkEditOrgContactsComponent,
        S25BulkEditRelationshipComponent,
        S25BulkResourceEditStockComponent,
        S25BulkLocationEditExpressComponent,
        S25BulkEditOrgPartitionPrefsComponent,
        S25BulkEventEditRequirementComponent,
        S25BulkLocationEditOptimizerEfectiveDating,
        S25BulkEditDeleteComponent,
        S25BulkLocationEditHoursComponent,
        S25BulkEditIssuesListComponent,
        S25BulkEditInheritComponent,
        S25BulkEditConstraintComponent,
        S25BulkEditDateRangeComponent,
        S25BulkEditActivationComponent,
        S25BulkEditEventTypeReportsComponent,
        S25BulkEditCopyComponent,
        S25BulkEditDateComponent,
        S25BulkEditAssigneeComponent,
        S25BulkEditTaskActionComponent,
        S25BulkEditEventTitleNameComponent,
        S25BulkEditUnassignComponent,
        S25BulkEditStateComponent,
        S25BulkEditCustAtrbComponent,
        S25BulkEditDataListComponent,
        S25BulkEditEventOwnerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        S25RichTextEditorModule,
        S25LoadingInlineModule,
        S25ToggleButtonModule,
        S25MultiselectModule,
        S25NotificationPolicyModule,
        S25OrgDetailsModule,
        S25DatepickerModule,
        S25EvDetailsModule,
        S25DeleteModule,
        S25TimepickerModule,
        S25ImageSelectorModule,
        S25ImageFormModule,
        S25ConstraintsModule,
        S25TaskModule,
        S25DropdownPaginatedModule,
        S25EditableModule,
        S25CheckboxModule,
        S25EventStatesModule,
        S25TableModule,
        S25HelpModule,
    ],
    providers: [
        S25BulkEditComponent,
        S25BulkLocationEditFillratioComponent,
        S25BulkEditCommenttInstrComponent,
        S25BulkLocationEditLayoutCapacityComponent,
        S25BulkLocationEditSchedulerComponent,
        S25BulkLocationEditPartitionComponent,
        S25BulkEditMultiselectComponent,
        S25BulkEditNotifyPolicyComponent,
        S25BulkEditDropdownComponent,
        S25BulkEditTextComponent,
        S25BulkEditSaveComponent,
        S25BulkEditOrgContactsComponent,
        S25BulkEditRelationshipComponent,
        S25BulkResourceEditStockComponent,
        S25BulkLocationEditExpressComponent,
        S25BulkEditOrgPartitionPrefsComponent,
        S25BulkEventEditRequirementComponent,
        S25BulkLocationEditOptimizerEfectiveDating,
        S25BulkEditDeleteComponent,
        S25BulkLocationEditHoursComponent,
        S25BulkEditIssuesListComponent,
        S25BulkEditInheritComponent,
        S25BulkEditConstraintComponent,
        S25BulkEditDateRangeComponent,
        S25BulkEditActivationComponent,
        S25BulkEditEventTypeReportsComponent,
        S25BulkEditCopyComponent,
        S25BulkEditDateComponent,
        S25BulkEditAssigneeComponent,
        S25BulkEditTaskActionComponent,
        S25BulkEditEventTitleNameComponent,
        S25BulkEditUnassignComponent,
        S25BulkEditStateComponent,
        S25BulkEditCustAtrbComponent,
        S25BulkEditDataListComponent,
        S25BulkEditEventOwnerComponent,
    ],
    exports: [
        S25BulkEditComponent,
        S25BulkLocationEditFillratioComponent,
        S25BulkEditCommenttInstrComponent,
        S25BulkLocationEditLayoutCapacityComponent,
        S25BulkLocationEditSchedulerComponent,
        S25BulkLocationEditPartitionComponent,
        S25BulkEditMultiselectComponent,
        S25BulkEditNotifyPolicyComponent,
        S25BulkEditDropdownComponent,
        S25BulkEditTextComponent,
        S25BulkEditSaveComponent,
        S25BulkEditOrgContactsComponent,
        S25BulkEditRelationshipComponent,
        S25BulkResourceEditStockComponent,
        S25BulkLocationEditExpressComponent,
        S25BulkEditOrgPartitionPrefsComponent,
        S25BulkEventEditRequirementComponent,
        S25BulkLocationEditOptimizerEfectiveDating,
        S25BulkEditDeleteComponent,
        S25BulkLocationEditHoursComponent,
        S25BulkEditIssuesListComponent,
        S25BulkEditInheritComponent,
        S25BulkEditConstraintComponent,
        S25BulkEditDateRangeComponent,
        S25BulkEditActivationComponent,
        S25BulkEditEventTypeReportsComponent,
        S25BulkEditCopyComponent,
        S25BulkEditDateComponent,
        S25BulkEditAssigneeComponent,
        S25BulkEditTaskActionComponent,
        S25BulkEditEventTitleNameComponent,
        S25BulkEditUnassignComponent,
        S25BulkEditStateComponent,
        S25BulkEditCustAtrbComponent,
        S25BulkEditDataListComponent,
        S25BulkEditEventOwnerComponent,
    ],
})
export class S25BulkEditModule {
    constructor() {}
}
